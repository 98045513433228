// Lazy loaded routes
import { FC, Suspense, lazy, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Outlet, Route, Routes, ScrollRestoration, useLocation } from 'react-router-dom';

import { helpers } from 'utils';
import { getCookie, onVa, setCookie } from 'utils/helpers';
import history, { parseUrl } from 'utils/history';
import useAuth from 'utils/hooks/useAuth';
import routes from 'utils/routes';

import { getLastActivityCookie } from 'redux/apis/OG/auth/utils';

import ExpirationDetector from 'components/ExpirationDetector';
import LoadingOverlay from 'components/common/LoadingOverlay';
import RequireAuth from 'components/routing/RequireAuth';
import VaOnly from 'components/routing/VaOnly';
import WellnessOnly from 'components/routing/WellnessOnly';

import useUi from '../utils/hooks/useUi';

// Lazy loaded routes
const Home = lazy(() => import('screens/Home'));
const SignIn = lazy(() => import('screens/SignIn'));

const Membership = lazy(() => import('screens/Membership'));

const AssistedStretching = lazy(() => import('screens/AssistedStretching'));
const InHomeWellness = lazy(() => import('screens/InHomeWellness'));
const InHomeMassage = lazy(() => import('screens/InHomeMassage'));
const MobileMassage = lazy(() => import('screens/MobileMassage'));
const MassageNearMe = lazy(() => import('screens/MassageNearMe'));
const MassageNearMeEmbed = lazy(() => import('screens/MassageNearMe/embed'));
const PersonalWellness = lazy(() => import('screens/PersonalWellness'));

const Va = lazy(() => import('screens/Va'));
const VaFlow = lazy(() => import('screens/VaFlow'));
const VaConsentFlow = lazy(() => import('screens/VaConsentFlow'));
const VaQuestionnaireFlow = lazy(() => import('screens/VaQuestionnaireFlow'));

const CorporateInHome = lazy(() => import('screens/CorporateInHome'));

const CorporateWellness = lazy(() => import('screens/CorporateWellness'));
const ChairMassage = lazy(() => import('screens/ChairMassage'));

const Estore = lazy(() => import('screens/Estore'));

const GiftCertificate = lazy(() => import('screens/GiftCertificate'));

const Provider = lazy(() => import('screens/Provider'));

const CovidTest = lazy(() => import('screens/CovidTest'));
const PhysicalTherapy = lazy(() => import('screens/PhysicalTherapy'));
const MedicalMassage = lazy(() => import('screens/MedicalMassage'));
const HealthCare = lazy(() => import('screens/HealthCare'));

const Accessibility = lazy(() => import('screens/Accessibility'));

const TrustAndSafety = lazy(() => import('screens/TrustAndSafety'));
const HelpCenter = lazy(() => import('screens/HelpCenter'));
const InvitePromo = lazy(() => import('screens/InvitePromo'));
const AboutUs = lazy(() => import('screens/AboutUs'));
const PressReleases = lazy(() => import('screens/PressReleases'));
const PressMediaNews = lazy(() => import('screens/PressMediaNews'));

const ErrorNotFound = lazy(() => import('screens/Errors/NotFound'));
const ErrorMaintenance = lazy(() => import('screens/Errors/Maintenance'));
const ErrorSomethingWentWrong = lazy(() => import('screens/Errors/SomethingWentWrong'));

const CalendarSync = lazy(() => import('screens/CalendarSync'));

const SpaStaffing = lazy(() => import('screens/SpaStaffing'));
const SpaIndividualRequest = lazy(() => import('screens/SpaIndividualRequest'));
const SpaBlockProjectRequest = lazy(() => import('screens/SpaBlockProjectRequest'));

const Sitemap = lazy(() => import('screens/Sitemap'));

const LocationPage = lazy(() => import('screens/LocationPage'));
const ChairLocationPage = lazy(() => import('screens/ChairLocationPage'));
const RestartApplication = lazy(() => import('screens/RestartApplication'));
const PaymentRates = lazy(() => import('screens/PaymentRates'));

const Settings = lazy(() => import('screens/Settings'));
const KemtaiExercises = lazy(() => import('screens/VaKemtaiExercises'));

const WebviewRedirect = lazy(() => import('screens/WebviewRedirect'));

const FallbackMatch = () => {
  const [loaded, setLoaded] = useState(false);
  const [isChairMatch, setIsChairMatch] = useState(null);
  const [isMatch, setIsMatch] = useState(null);
  const location = useLocation();
  const { fromMobile } = useUi();
  useLayoutEffect(() => {
    if (helpers.onVa()) window.location.href = `${process.env.REACT_APP_OG_HOST}${location?.pathname}`;
    if (location?.pathname?.endsWith('-corporate-chair-massage')) setIsChairMatch(true);
    else if (location?.pathname?.endsWith('-massage')) setIsMatch(true);
    setLoaded(true);
  }, []);

  return loaded ? (
    helpers.onVa() ? null : isChairMatch ? (
      <ChairLocationPage />
    ) : isMatch ? (
      <LocationPage />
    ) : fromMobile ? (
      <ErrorSomethingWentWrong />
    ) : (
      <ErrorNotFound />
    )
  ) : null;
};

const AllRoutes: FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [isVa, setIsVa] = useState<boolean>(false);
  const historyListener = useRef<any>();
  const { isAuthenticated } = useAuth();
  const [lastActivity, setLastActivity] = useState<any>();

  useEffect(() => {
    const interval = setInterval(() => {
      setLastActivity(getLastActivityCookie());
    }, 60000);
    return () => {
      if (interval) clearInterval(interval);
    };
  }, []);

  // checks if url is VA on any route change and re-renders
  // if it's the case (by updating local state)
  const checkSubDomain = useCallback(() => {
    const _isVa = onVa();
    if (isVa !== _isVa) setIsVa(_isVa);
  }, [isVa]);

  useEffect(() => {
    const urlParams = parseUrl(window.location.search) || {};
    const marketing_cookies = [
      'gclid',
      'gsource',
      'utm_campaign',
      'utm_source',
      'utm_medium',
      'utm_term',
      'utm_content',
    ];

    marketing_cookies.forEach((cookie) => {
      if (getCookie(cookie)) {
        return;
      }
      if (urlParams[cookie]) {
        // set cookie with 30 days expiration
        setCookie(cookie, urlParams[cookie], { expires: new Date(Date.now() + 30 * 86400 * 1000) });
      }
    });

    if (urlParams.va === '1') {
      (window as any).va_mode = true;
    }
    checkSubDomain();

    historyListener.current = history.listen(() => {
      checkSubDomain();
    });

    setLoading(false);

    return () => {
      if (historyListener.current) {
        historyListener.current?.();
      }
    };
  }, [checkSubDomain]);

  if (loading) return null;

  return (
    <Suspense fallback={<LoadingOverlay visible={true} />}>
      <Routes>
        {/* VA - If the url has subdomain VA or if its dev environment (where the va routes are all prefixed by "/va") */}
        <Route path={routes.index()} element={isVa ? <Va /> : <Home />} />

        {/* Accessibility */}
        <Route path={routes.SIGN_IN()} element={<SignIn />} />
        <Route path={routes.ACCESSIBILITY()} element={<Accessibility />} />
        <Route path={routes.MAINTENANCE()} element={<ErrorMaintenance />} />

        {/* VA */}
        <Route
          element={
            <VaOnly>
              <Outlet />
            </VaOnly>
          }
        >
          <Route path={routes.VA_FLOW.index({ catchAll: true })} element={<VaFlow />} />
          <Route path={routes.VA_QUESTIONNAIRE_FLOW.index({ catchAll: true })} element={<VaQuestionnaireFlow />} />
          <Route path={routes.VA_CONSENT_FLOW.index({ catchAll: true })} element={<VaConsentFlow />} />
        </Route>

        {/* Wellness */}
        <Route
          element={
            <WellnessOnly>
              <Outlet />
            </WellnessOnly>
          }
        >
          {/* @Work */}
          <Route path={routes.CORPORATE_IN_HOME.index({ catchAll: true })} element={<CorporateInHome />} />
          <Route path={routes.CORPORATE_WELLNESS.index({ catchAll: true })} element={<CorporateWellness />} />
          <Route path={routes.CHAIR_MASSAGE.index({ catchAll: true })} element={<ChairMassage />} />
          {/* @Home */}
          <Route path={routes.ASSISTED_STRETCHING()} element={<AssistedStretching />} />
          <Route path={routes.IN_HOME_WELLNESS()} element={<InHomeWellness />} />
          <Route path={routes.IN_HOME_MASSAGE()} element={<InHomeMassage />} />
          <Route path={routes.MOBILE_MASSAGE()} element={<MobileMassage />} />
          <Route path={routes.MASSAGE_NEAR_ME()} element={<MassageNearMe />} />
          <Route path={routes.MASSAGE_NEAR_ME_EMBED()} element={<MassageNearMeEmbed />} />
          <Route path={routes.PERSONAL_WELLNESS.index({ catchAll: true })} element={<PersonalWellness />} />
          {/* Membership */}
          <Route path={routes.MEMBERSHIP.index({ catchAll: true })} element={<Membership />} />
          {/* Provider */}
          <Route path={routes.PROVIDER.index({ catchAll: true })} element={<Provider />} />
          {/* E-store */}
          <Route path={routes.ESTORE.index({ catchAll: true })} element={<Estore />} />
          {/* Gift Certificate */}
          <Route path={routes.GIFT_CERTIFICATE.index({ catchAll: true })} element={<GiftCertificate />} />
          {/* Medical */}
          <Route path={routes.COVID()} element={<CovidTest />} />
          <Route path={routes.PHYSICAL_THERAPY()} element={<PhysicalTherapy />} />
          <Route path={routes.MEDICAL_MASSAGE()} element={<MedicalMassage />} />
          <Route path={routes.HEALTHCARE()} element={<HealthCare />} />

          {/* Spa */}
          <Route path={routes.SPA()} element={<SpaStaffing />} />
          <Route
            path={routes.SPA_INDIVIDUAL_REQUEST.index({ catchAll: true })}
            element={
              <RequireAuth isType='spa' redirectTo={routes.index({ host: true })}>
                <SpaIndividualRequest />
              </RequireAuth>
            }
          />
          <Route
            path={routes.SPA_BLOCK_PROJECT_REQUEST.index({ catchAll: true })}
            element={
              <RequireAuth isType='spa'>
                <SpaBlockProjectRequest />
              </RequireAuth>
            }
          />

          {/* General pages */}
          <Route path={routes.TRUST_AND_SAFETY.index({ catchAll: true })} element={<TrustAndSafety />} />
          <Route path={routes.HELP.index({ catchAll: true })} element={<HelpCenter />} />
          <Route path={routes.ABOUT_US()} element={<AboutUs />} />
          <Route path={routes.PRESS_RELEASES.index({ catchAll: true })} element={<PressReleases />} />
          <Route path={routes.PRESS_MEDIA_NEWS()} element={<PressMediaNews />} />

          <Route path={routes.SITEMAP()} element={<Sitemap />} />

          <Route
            path={routes.INVITE_PROMO()}
            element={
              <RequireAuth>
                <InvitePromo />
              </RequireAuth>
            }
          />
          {/* Calendar */}
          <Route path={routes.CALENDAR_SYNC()} element={<CalendarSync />} />
          {/* Restart Application */}
          <Route path={routes.RESTART_APPLICATION()} element={<RestartApplication />} />
          {/* Payment Rates */}
          <Route path={routes.PAYMENT_RATES()} element={<PaymentRates />} />

          {/* Settings */}
          <Route
            path={routes.SETTINGS.index({ catchAll: true })}
            element={
              <RequireAuth>
                <Settings />
              </RequireAuth>
            }
          />

          {/* Webview Redirect */}
          <Route path={routes.WEBVIEW_REDIRECT()} element={<WebviewRedirect />} />

          {/* Kemtai */}
          <Route
            path={routes.KEMTAI_EXERCISES()}
            element={
              <RequireAuth>
                <KemtaiExercises />
              </RequireAuth>
            }
          />
        </Route>

        <Route path='/*' element={<FallbackMatch />} />
      </Routes>
      <ScrollRestoration />

      {onVa() && isAuthenticated && <ExpirationDetector expiresAt={lastActivity ? lastActivity + 3600000 : 0} />}
    </Suspense>
  );
};

export default AllRoutes;
