import get from 'lodash/get';
import set from 'lodash/set';

import { rollbar } from '@zeel-dev/zeel-ui';

import * as helpers from './helpers';
import { formatQuery } from './history';

const routes: RoutesType = {
  _index: ``,

  /*** @WORK ***/
  CORPORATE_WELLNESS: {
    _index: `corporate-wellness`,
    MINDFULNESS: `mindfulness-meditation`,
    YOGA: `yoga`,
    STRETCHING: `stretching`,
  },

  CHAIR_MASSAGE: {
    _index: `chair-massage`,
    BOOK: `book`,
  },

  CORPORATE_IN_HOME: {
    _index: `in-home-massage-for-employees`,
    ACCOUNT: `account`,
    SIGN_IN: `sign-in`,
    ELIGIBILITY: `verify-eligibility`,
  },

  /*** SPA ***/
  SPA: `spa-staffing`,
  SPA_INDIVIDUAL_REQUEST: {
    _index: `new-individual-request`,
    NEW: `new/:clientId?/:locationId?`,
  },

  SPA_BLOCK_PROJECT_REQUEST: {
    _index: `new-block-project-request`,
    NEW: `new/:clientId?/:locationId?`,
  },

  /*** WELLNESS ***/
  ASSISTED_STRETCHING: `assisted-stretching`,
  IN_HOME_WELLNESS: `in-home-wellness`,
  IN_HOME_MASSAGE: `in-home-massage`,
  MOBILE_MASSAGE: `mobile-massage`,
  MASSAGE_NEAR_ME: `massage-near-me`,
  MASSAGE_NEAR_ME_EMBED: `massage-near-me/embed`,
  PERSONAL_WELLNESS: {
    _index: `massage-on-demand`,
    SERVICE: `services`,
    DETAILS: `table`,
    LOCATION: `table_location`,
    TIME: `table_time`,
    PRICING: `pricing`,
    SUMMARY: `review`,
    CREATE_ACCOUNT: `signup_account`,
    ID_CONFIRMATION: `signup_identity`,
    JUMIO: `signup_jumio/:response`,
    APPOINTMENT_PENDING: `pending/:id/:type?`,
    APPOINTMENT_CONFIRMED: `confirmed/:id`,
  },

  /*** VA ***/
  VA_MICROSITE: `va/:link?`,
  VA_FLOW: {
    _index: `flow`,
    SIGN_IN: `sign-in`,
    ACCOUNT: `account`,
    FIND_REFERRAL: `find-referral`,
    NO_REFERRAL_FOUND: `no-referral-found`,
    SERVICE: `service`,
    DETAILS: `details`,
    LOCATION: `location`,
    TIME: `time`,
    FORMS: `forms`,
    SUMMARY: `summary`,
    APPOINTMENT: `appointment/:id`,
  },

  VA_CONSENT_FLOW: {
    _index: `consent`,
    IDENTITY: `identity`,
    DETAILS: `details`,
    FORMS: `forms`,
    COMPLETED: `completed`,
    ERROR: `error`,
  },

  VA_QUESTIONNAIRE_FLOW: {
    _index: `evaluation`,
    SIGN_IN: `sign-in`,
    DETAILS: `details`,
    QUESTIONNAIRE: `questionnaire`,
    NOTES: `notes`,
    REVIEW_PROVIDER: `review-provider`,
    FEEDBACK: `feedback`,
    ERROR: `error`,
  },

  /*** MEMBERSHIP ***/
  MEMBERSHIP: {
    _index: `massage-membership`,
    FLOW: {
      _index: `signup`,
      SELECT_MEMBERSHIP: `select-membership`,
      SIGN_IN: `sign-in`,
      CREATE_ACCOUNT: `create-an-account`,
      HOME_ADDRESS: `home`,
      MAILING_ADDRESS: `mailing`,
      SUMMARY: `review`,
      COMPLETE: `complete`,
    },
  },

  /*** E-STORE ***/
  ESTORE: {
    _index: `store`,
    ACCOUNT: `account`,
    SHIPPING: `shipping`,
    SUMMARY: `summary`,
    ORDER_DETAILS: `order/:id`,
  },

  /*** PROVIDER ***/
  PROVIDER: {
    _index: `provider`,
    REFERRAL_CODE: `:referralCode`,
    APPLICATION: `application`,
  },

  /*** HELP CENTER ***/
  HELP: {
    _index: `help`,
    PAGE: `:slug`,
    SEARCH_RESULTS: `search`,
  },

  /*** GIFT CERTIFICATES ***/
  GIFT_CERTIFICATE: {
    _index: `massage-gift-certificate`,
    CORPORATE: 'corporate',
    FLOW: {
      _index: 'flow',
      TYPE: `type`,
      STYLE: `style`,
      DETAILS: `details`,
      CHECKOUT: `checkout`,
      SUCCESS: `success`,
    },
  },

  /** GENERAL */
  INVITE_PROMO: `4/invite`,
  CONCIERGE_BOOKINGS: `/4/concierge/bookings/upcoming`,
  CONCIERGE_PAST_BOOKINGS: `/4/concierge/bookings/past`,
  PRIVACY_PRACTICES: `https://health.zeel.com/privacy`,
  CAREER: `https://zeel.bamboohr.com/jobs/`,
  MAINTENANCE: `maintenance`,
  SOMETHING_WENT_WRONG: `something-went-wrong`,
  ACCESSIBILITY: `accessibility`,
  HEALTHCARE: `healthcare`,
  PPE: `https://ppe.zeel.com`,
  PHYSICAL_THERAPY: `physical-therapy`,
  MEDICAL_MASSAGE: `insurance-covered-massage`,
  COVID: `covid`,
  ABOUT_US: `about-us`,
  PRESS_RELEASES: {
    _index: `press-releases`,
    POST: ':slug',
  },

  TRUST_AND_SAFETY: {
    _index: `trust-and-safety`,
    HEALTH: `health`,
    CONSUMER: `consumers`,
    PROVIDER: `providers`,
    BUSINESS: `businesses`,
    TEAM: `team`,
  },

  PRESS_MEDIA_NEWS: `press-media-news`,
  SOCIAL_TWITTER: `https://twitter.com/GetZeel`,
  SOCIAL_FACEBOOK: `https://www.facebook.com/GetZeel`,
  SOCIAL_INSTAGRAM: `https://www.instagram.com/getzeel`,
  SOCIAL_YELP: `https://www.yelp.com/biz/zeel-massage-on-demand-new-york-2`,
  STORE_GOOGLE: `https://play.google.com/store/apps/details?id=com.zeel.consumer`,
  STORE_APPLE: `https://itunes.apple.com/us/app/massage-on-demand-in-home/id621692157?ls=1&mt=8`,
  SITEMAP: `sitemap`,
  HSA_FSA_PAYMENT: `hsa-fsa-payment`,

  /*** OG PAGES ***/
  SIGN_IN: `login`, // hybrid
  SIGN_UP: `signup`,
  PRIVACY_POLICY: `view/privacy-policy`,
  TERMS_OF_USE: `terms-of-use`,

  CMS: `cms/start`,
  BLOG: `blog`,
  APPLY: `apply`,
  MASSAGES_NEAR_ME: `massage-near-me`,
  MASSAGE_GIFT_CERTIFICATE: `massage-gift-certificate`,
  MASSAGE_MOBILE: `mobile-massage`,
  MASSAGE_SERVICES: `massage-services`,
  ZEEL_PROMISE: `zeel-massage-wellness`,
  // Account
  ACCOUNT: `account`,
  ACCOUNT_CREDIT: `account/credit`,
  ACCOUNT_PROVIDER: `3/therapist`,
  ACCOUNT_CONCIERGE: `4/concierge`,

  // Settings
  SETTINGS: {
    _index: `settings`,
    PERSONAL_ACCOUNT: {
      _index: `personal/account`,
      IDENTITY: `identity`,
      LOCATIONS: `locations`,
      PAYMENT: `payment`,
      PEOPLE: `people`,
      CREDIT: `credit`,
    },
    PERSONAL_MEMBERSHIP: {
      _index: `personal/membership`,
      GIFT: `gift/:id?`,
      CANCEL: `cancel`,
      GIFT_CANCEL: `gift/:id/cancel`,
    },
    PERSONAL_APPOINTMENTS: {
      _index: `personal/appointments`,
      UPCOMING: `upcoming`,
      COMPLETED: `completed`,
    },
  },
  PERSONAL_APPOINTMENTS: `account/appointments`,
  PERSONAL_STORE_ORDERS: `account/orders`,
  PERSONAL_THERAPISTS: `account/therapists`,
  KEMTAI_EXERCISES: `self-care-exercises`,

  WEBVIEW_REDIRECT: `webview/redirect`,

  // Massage techniques
  MASSAGE_TECHNIQUES: `massage-techniques`,
  MASSAGE_SWEDISH: `massage-techniques/swedish-massage`,
  MASSAGE_DEEP_TISSUE: `massage-techniques/deep-tissue-massage`,
  MASSAGE_PRENATAL: `massage-techniques/prenatal-massage`,
  MASSAGE_SPORTS: `massage-techniques/sports-massage`,
  MASSAGE_SLEEP: `massage-techniques/sleep-massage`,
  MASSAGE_COUPLES: `massage-techniques/couples-massage`,
  MASSAGE_SINGLE: `massage-techniques/single-massage`,
  MASSAGE_B2B: `massage-techniques/back-to-back-massage`,
  // Coverage Cities Chair Massage
  CHAIR_MASSAGE_DALLAS: `dallas-corporate-chair-massage`,
  CHAIR_MASSAGE_DENVER: `denver-corporate-chair-massage`,
  CHAIR_MASSAGE_HOUSTON: `houston-corporate-chair-massage`,
  CHAIR_MASSAGE_NYC: `nyc-corporate-chair-massage`,
  CHAIR_MASSAGE_SEATTLE: `seattle-corporate-chair-massage`,
  CHAIR_MASSAGE_SAN_FRANCISCO: `san-francisco-corporate-chair-massage`,
  CHAIR_MASSAGE_LOS_ANGELES: `los-angeles-corporate-chair-massage`,
  CHAIR_MASSAGE_MIAMI: `miami-corporate-chair-massage`,
  CHAIR_MASSAGE_AUSTIN: `austin-corporate-chair-massage`,
  CHAIR_MASSAGE_PORTLAND: `portland-corporate-chair-massage`,
  CHAIR_MASSAGE_PHOENIX: `phoenix-corporate-chair-massage`,
  CHAIR_MASSAGE_CHICAGO: `chicago-corporate-chair-massage`,
  // Coverage Cities In-Home Massage
  IN_HOME_MASSAGE_ATLANTA: `atlanta-metro-ga-massage`,
  IN_HOME_MASSAGE_AUSTIN: `austin-tx-massage`,
  IN_HOME_MASSAGE_CHICAGO: `chicago-il-massage`,
  IN_HOME_MASSAGE_DALLAS_FORTH_WORTH: `dfw-massage`,
  IN_HOME_MASSAGE_DENVER: `denver-co-massage`,
  IN_HOME_MASSAGE_HOUSTON: `houston-massage`,
  IN_HOME_MASSAGE_LOS_ANGELES: `los-angeles-ca-massage`,
  IN_HOME_MASSAGE_MIAMI: `miami-fl-massage`,
  IN_HOME_MASSAGE_NYC: `new-york-city-massage`,
  IN_HOME_MASSAGE_PHOENIX: `phoenix-az-massage`,
  IN_HOME_MASSAGE_SAN_FRANCISCO: `san-francisco-bay-area-massage`,
  // CALENDAR
  CALENDAR_SYNC: `calendar/successful-sync`,
  // Restart Application
  RESTART_APPLICATION: `reopen-application`,
  // Payment Rates
  PAYMENT_RATES: `payment-rates`,
};

type RoutesType<S = string> = {
  _index: S;
  [key: string]: S | RoutesType<S>;
};

type FormattedRoutesType = {
  [key: string]: any;
};

type RouteGeneratorOptions = {
  params?: {
    [key: string]: string | number;
  };
  search?: { [key: string]: string | number | boolean };
  searchRaw?: string;
  leaf?: boolean;
  catchAll?: boolean;
  host?: boolean;
};

type RouteGeneratorFn = (o?: RouteGeneratorOptions) => string;

const routeGenerator =
  (currentLeaf: string, currentPath: string[]): RouteGeneratorFn =>
  (options) => {
    const { params = {}, search, searchRaw, leaf, catchAll, host } = options || {};
    const constructedPath = (leaf ? currentLeaf : currentPath.join('/'))
      .split('/')
      .map((p) => {
        const isParam = p[0] === ':';
        const isOptional = p[p.length - 1] === '?';
        let paramName = p.replace(':', '');
        if (isOptional) paramName = paramName.replace('?', '');
        if (!isParam) return p;

        if (params[paramName]) {
          return params[paramName];
        } else {
          if (isOptional && !leaf) return 'SKIP';
          if (!isOptional && !leaf) {
            const err = `Missing parameter {${paramName}} when generating path: ${currentPath.join(
              '/'
            )}. Options passed: ${JSON.stringify(options) || 'none'}`;
            helpers.onLocalEnvironment() ? console.error(err) : rollbar.warn(err);
          }

          return p;
        }
      })
      .filter((s) => s !== 'SKIP')
      .join('/');

    let constructedPathWithQuery = search
      ? formatQuery(search, constructedPath)
      : searchRaw
      ? `${constructedPath}${searchRaw}`
      : constructedPath;
    if (catchAll) {
      if (constructedPathWithQuery[constructedPathWithQuery.length - 1] === '/') constructedPathWithQuery += '*';
      else constructedPathWithQuery += '/*';
    }
    if (host && helpers.isInjected()) {
      if (leaf) console.error('Cannot use "leaf" and "host" together - host will behave as if it was falsy');
      else constructedPathWithQuery = `${helpers.getCurrentHost()}/${constructedPathWithQuery}`;
    }

    if (!helpers.isLinkFull(constructedPathWithQuery)) {
      constructedPathWithQuery = `/${constructedPathWithQuery}`;
    }

    return constructedPathWithQuery;
  };

const formatRoutes = (current: RoutesType, path = [], pathValues = [], compiled = {}): FormattedRoutesType => {
  Object.entries(current).map(([key, val = '']) => {
    if (key === '_index') key = 'index';
    const _path = [...path, key];

    const _pathValues = [
      ...pathValues,
      ...(key === 'index' ? [] : typeof val === 'string' ? [val] : [(val as RoutesType)._index]),
    ];

    compiled =
      typeof val === 'string'
        ? set(compiled, _path, routeGenerator(val, _pathValues))
        : formatRoutes(val, _path, _pathValues, compiled);
  });
  return compiled;
};

const formattedRoutes = formatRoutes(routes);

export const getRouteTab = (params: { noFallback?: boolean } = {}) => {
  const tabMap = {
    business: {
      includes: ['CORPORATE_WELLNESS.index', 'CHAIR_MASSAGE.index', 'SPA'],
    },
    health: {
      includes: ['HEALTHCARE'],
    },
    provider: {
      includes: ['PROVIDER.index'],
    },
  };
  let activeTab;

  Object.keys(tabMap).forEach((tab) => {
    const { includes = [] } = tabMap[tab] || ({} as any);
    includes.forEach((tabInclude) => {
      const r = (get(formattedRoutes, tabInclude) as RouteGeneratorFn)?.();
      if (r && (window.location?.pathname || '').includes(r)) activeTab = tab;
    });
  });
  return activeTab || (params?.noFallback ? null : 'personal');
};

export default formattedRoutes;
