import { useEffect } from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';

import Routes from 'screens';

import { onVa } from 'utils/helpers';

import useAuthCookieWatcher from 'redux/features/auth/useAuthCookieWatcher';

import { ModalProvider, ThemeProvider, defaultTheme } from '@zeel-dev/zeel-ui';
import AppHead from 'components/AppHead';

import Debugger from './components/Debugger';
import { useSearchParams } from './utils/hooks';

const App = () => {
  useAuthCookieWatcher();
  const { debug } = useSearchParams();

  useEffect(() => {
    if (debug && !(console as any).logs) {
      (console as any).defaultLog = console.log.bind(console);
      (console as any).defaultWarn = console.warn.bind(console);
      (console as any).defaultError = console.error.bind(console);
      (console as any).logs = [];
      console.log = function (...args: any[]) {
        (console as any).defaultLog.apply(console, args);
        (console as any).logs.push([...Array.from(args)]);
      };
      console.warn = function (...args: any[]) {
        (console as any).defaultWarn.apply(console, args);
        (console as any).logs.push(['warning', ...Array.from(args)]);
      };
      console.error = function (...args: any[]) {
        (console as any).defaultError.apply(console, args);
        (console as any).logs.push(['error', ...Array.from(args)]);
      };
    }
    const showEvent = (e) => {
      if (e.persisted && onVa()) document.location.reload();
    };
    window.addEventListener('pageshow', showEvent);
    return () => window.removeEventListener('pageshow', showEvent);
  }, []);

  return (
    <ThemeProvider theme={defaultTheme}>
      <ModalProvider>
        <ParallaxProvider>
          <AppHead />
          <Routes />
          {debug && <Debugger position={debug} />}
        </ParallaxProvider>
      </ModalProvider>
    </ThemeProvider>
  );
};

export default App;
